import { PublicConfig } from '../../models/environment.public';
import { commonEnvVars, devEnv } from 'src/common/environments/environment.public.common';

const { features, ...rest } = devEnv;

const config: PublicConfig = {
    appId: 'l7xx95acd4d3e19b4a0a9d60b5ecbf061f84',
    brand: 'Carrier',
    brandPath: 'carrier',
    publicBaseUrl: 'https://dev-carrier.hvacpartners.com',
    siteCode: 638,
    jpMorganPath: 'https://chase-var',
    features: {
        ...features,
        covid19Information: true,
        newsLetterSignUp: false,
        auth: {
            login: true,
            externalLogin: false
        }
    },
    ...rest
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const environment: any = { ...commonEnvVars(config) };
